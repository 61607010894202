<syslink-modal
    #modal
    title="Send mail"
    width="700px"
    validateText="Send mail" 
    cancelText="Cancel"
    (onValidate)="onValidateButtonClicked()"
>
    <ng-container *ngIf="mailModel.FromThird">
        <!-- <syslink-switch
            label="Send report"
            [(model)]="mailModel.SendReport"
        ></syslink-switch> -->
        <syslink-input-text
            *ngIf="mailModel.FromThird"
            label="From"
            [model]="mailModel.FromThird"
            [disabled]="true"
            [usedModificationDetect]="false"
        ></syslink-input-text>

        <syslink-custom-tagbox
            *ngIf="mailModel.FromThird"
            #toTagBox
            label="To"
            [store]="thirdContactInformationsService.store"
            [filter]="filterToContactInformation"
            [select]="['Id', 'Value', 'ThirdId.Fullname']"
            [expand]="['ThirdId']"
            displayKey="Value"
            [(model)]="mailModel.ToThirdContactInformationId"
            (onCustomeValue)="onCustomeValue($event,'TO')"
            [canRefresh]="true"
            [allowAddCustomItem]="false"
            [usedModificationDetect]="false"
        ></syslink-custom-tagbox>

        <syslink-custom-tagbox
            *ngIf="mailModel.FromThird"
            #ccTagBox
            label="CC"
            [store]="thirdContactInformationsService.store"
            [filter]="filterToContactInformation"
            [select]="['Id', 'Value', 'ThirdId.Fullname']"
            [expand]="['ThirdId']"
            displayKey="Value"
            [(model)]="mailModel.CCThirdContactInformationId"
            (onCustomeValue)="onCustomeValue($event,'CC')"
            [canRefresh]="true"
            [allowAddCustomItem]="false"
            [usedModificationDetect]="false"
        ></syslink-custom-tagbox>

        <syslink-custom-tagbox
            *ngIf="mailModel.FromThird"
            #cciTagBox
            label="CCI"
            [store]="thirdContactInformationsService.store"
            [filter]="filterToContactInformation"
            [select]="['Id', 'Value', 'ThirdId.Fullname']"
            [expand]="['ThirdId']"
            displayKey="Value"
            [(model)]="mailModel.CCIThirdContactInformationId"
            (onCustomeValue)="onCustomeValue($event,'CCI')"
            [canRefresh]="true"
            [allowAddCustomItem]="false"
            [usedModificationDetect]="false"
        ></syslink-custom-tagbox>

        <syslink-select
            *ngIf="mailTemplateType"
            label="MailTemplate"
            [store]="mailTemplatesService.store"
            [(model)]="mailTemplate"
            [filter]="filterMailTemplate"
            (modelChange)="loadMailTemplateData()"
            [usedModificationDetect]="false"
        ></syslink-select>
        
        <syslink-input-text
            *ngIf="mailTemplate && mailTemplate.Id"
            label="Subject"
            [(model)]="mailModel.Subject"
            [usedModificationDetect]="false"
        ></syslink-input-text>

        <div class="row">
            <div class="col-4">
                <syslink-button text="Add files" (onClick)="fileManagerModal.open()"></syslink-button>
            </div>
            <div class="col-8">
                <ng-container  *ngFor="let selectedFile of selectedFiles">
                    <div class="fw-bold"> {{selectedFile.name}} </div>
                 </ng-container>
            </div>
        </div>
     
        <syslink-wysiwyg
            *ngIf="mailTemplate && mailTemplate.Id"
            label="Content"
            [(model)]="mailModel.Content"
            [showTable]="false"
            [multilineToolbar]="false"
            height="300"
            [usedModificationDetect]="false"
        ></syslink-wysiwyg>
    </ng-container>
</syslink-modal>

<syslink-modal
    #fileManagerModal
    title="Files"
    [noFooter]="true"
>
<!-- [selectedItemKeys]="" -->
    <syslink-file-manager
        #fileManager
        [entityType]="documentType + 'Header'"
        [entityId]="element.Id"
        (selectedItemKeysChange)="onFileSelectedChange($event)"
        [options]="{
            permissions: {
                create: true,
                copy: true,
                move: true,
                delete: true,
                rename: true,
                upload: true,
                download: true
            }
        }"
    ></syslink-file-manager>
</syslink-modal>

<syslink-modal
    #addToEmailModal
    title="Mail doesn't exist"
    width="600"
    [noFooter]="true"
>
    <div class="row">
        <div class="col-lg-4">
            <syslink-button
                text="Link to a third"
                (click)="onLinkToThirdClicked()"
            ></syslink-button>
        </div>
        <div class="col-4">
            <syslink-button
                text="Add new third"
                (click)="onAddNewThirdClicked()"
            ></syslink-button>
        </div>
        <div class="col-4">
            <syslink-button
                text="Use specifi mail"
                (click)="onUsedSpecificMailClicked()"
            ></syslink-button>
        </div>
    </div>
</syslink-modal>

<syslink-third-modal
    #ThirdModal
    [store]="thirdsService.store"
    (onValidate)="onAddEmailToThird($event)"
    [extraFields]="addEmailToThirdfields"
></syslink-third-modal>